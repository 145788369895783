@import '../../../styles/color.scss';

.vertical-thread-holder {
  $vertical-label-initial-offset: -36px;
  $vertical-label-last-offset: -8px;
  $vertical-label-main-height: 1.1em;
  $vertical-line-label-offset: 4px;
  $vertical-first-of-pair-content-offset: 36px;
  $vertical-second-of-pair-content-offset: 36px;
  $vertical-content-subtitle-y-offset: -24px;
  $vertical-content-subtitle-x-offset: -44px;
  .thread {
    &__grid-layout {
      display: grid;
      grid-template-columns: 5rem 1fr;
      grid-auto-flow: row;

      * + * {
        margin-top: 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      div > p + p {
        margin-top: 0.4em;
      }

      a {
        color: $text-description;
      }

      ul {
        padding-left: 1em;
      }

      > :nth-child(3n - 2) {
        // vertical thread grid track
        grid-row: span 2;
        position: relative;
        p {
          font-size: 0.95em;
          height: $vertical-label-main-height;
          overflow: hidden;
          text-align: center;
          letter-spacing: -0.8px;
        }
      }
      > :nth-child(3n - 1),
      > :nth-child(3n) {
        // first box of the pair
        position: relative;
        font-size: 0.75em;
        color: $text-description;
      }
      > :nth-child(3n - 1) {
        margin-top: calc(
          $vertical-label-main-height + $vertical-line-label-offset +
            $vertical-first-of-pair-content-offset
        );
      }
      > :nth-child(3n - 1)::before,
      > :nth-child(3n)::before {
        font-size: 1.1em;
        letter-spacing: -0.8px;
        font-weight: 400;
        font-style: italic;
        color: $text-small;
        position: absolute;
        top: $vertical-content-subtitle-y-offset;
        left: $vertical-content-subtitle-x-offset;
        text-indent: 0.8em;
        background: 0% 50%
          url(../../../assets/icons/marker-on-vertical-line.svg) no-repeat;
      }
      > :nth-child(3n - 1)::before {
        content: 'back-end';
      }
      > :nth-child(3n)::before {
        content: 'front-end';
      }
      > :nth-child(3n) {
        margin-top: $vertical-second-of-pair-content-offset;
      }
      > :nth-child(3n - 2)::after {
        content: '';
        position: absolute;
        top: calc($vertical-label-main-height + $vertical-line-label-offset);
        bottom: $vertical-line-label-offset;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1px;
        background-color: $text-main;
      }
      > :first-child::before {
        content: '';
        position: absolute;
        top: $vertical-label-initial-offset;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1px;
        background-color: $text-main;
      }
      > :nth-last-child(3)::after {
        bottom: calc(
          0px + $vertical-label-last-offset - $vertical-line-label-offset
        );
      }
    }
  }

  @media (min-width: 768px) {
    $vertical-line-label-y-offset: -36px;
    $vertical-line-label-x-offset: 40%;
    $vertical-pair-content-offset: 12px;
    .thread {
      &__grid-layout {
        grid-template-columns: 1fr 7rem 1fr;
        grid-auto-flow: row;
        > :nth-child(3n) {
          margin-top: 0;
          display: flex;
          align-items: center;
        }
        > :nth-child(3n - 2) {
          grid-column: 2 / 3;
        }
        > :nth-child(3n - 1) {
          grid-column: 1 / 2;
          margin-top: 0;
          display: flex;
          align-items: center;
        }
        > :nth-child(3n + 2),
        > :nth-child(3n + 3) {
          margin-top: $vertical-pair-content-offset;
          margin-bottom: $vertical-pair-content-offset;
        }
        > :nth-child(3n - 1)::before,
        > :nth-child(3n)::before {
          display: none;
        }
        > :nth-child(2)::before {
          content: 'Back-end';
          left: unset;
          right: $vertical-line-label-x-offset;
        }
        > :nth-child(3)::before {
          content: 'Front-end';
          left: $vertical-line-label-x-offset;
        }
        > :nth-child(2)::before,
        > :nth-child(3)::before {
          display: block;
          background: none;
          position: absolute;
          top: calc(
            $vertical-line-label-y-offset - $vertical-pair-content-offset
          );
          text-indent: 0;
        }
      }
    }
  }

  @media (min-width: 1068px) {
    .thread {
      &__grid-layout {
        grid-template-columns: 1fr 9rem 1fr;
      }
    }
  }
}
