@import '../../../styles//color.scss';

.footer-holder {
  margin-top: 0;
  padding-top: 1em;
  padding-bottom: 2em;
  background-color: $dark-background;
  .fluid-container {
    border-top: 1px solid rgb(66, 66, 69);
  }
  .footer {
    &__wrapper {
      padding-top: 1em;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 768px) {
      &__wrapper {
        max-width: 66.7%;
      }
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-main;
    }
    &__sitemap-col {
      flex: 1 1 50%;
      font-size: 0.75em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        color: $text-description;
        text-decoration: none;
      }
      a + a {
        margin-top: 0.75em;
      }
      a:hover {
        text-decoration: underline;
        color: $text-main;
      }
    }
    &__social-col {
      flex: 1 1 50%;
      align-self: flex-end;
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }
    &__social-media {
      * {
        margin-top: 0;
      }
      a {
        color: rgb(144, 144, 150);
      }
      a:hover {
        color: $text-main;
      }
      display: flex;
      align-items: center;
      column-gap: 0.8em;
      svg {
        vertical-align: middle;
      }
    }
    &__copyright-entry {
      font-size: 0.75em;
      margin-top: 0.75em;
    }
  }
}
