@import '../../../../styles/color.scss';

.gallery-card-holder {
  .gallery-card {
    &__affix {
      position: sticky;
      top: 3em;
      > div + div {
        margin-top: 2em;
      }
      .gallery-card__description-row {
        margin-top: 1.5em;
      }
    }
    &__title {
      color: $text-main;
      letter-spacing: -1px;
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    &__description {
      margin-top: 0;
      font-size: 0.75em;
      line-height: 1.2em;
      font-style: normal;
      color: $text-description;
    }
    &__image-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 0.25em;
      overflow: hidden;
    }
    &__image {
      margin-top: 0;
      object-fit: contain;
      max-width: 100%;
    }

    @media (min-width: 768px) {
      // tablet
      &__affix {
        position: static;
        > div + div {
          margin-top: 4.66em;
          .gallery-card__title {
            display: none;
          }
        }
        .gallery-card__description-row {
          font-size: 1em;
        }
        > :nth-child(odd) {
          .gallery-card__flex-card {
            flex-direction: row-reverse;
          }
          .gallery-card__flex {
            margin-left: 1.5em;
          }
          @media (min-width: 1068px) {
            .gallery-card__flex {
              margin-left: 2.5em;
            }
          }
        }
        > :nth-child(even) {
          .gallery-card__flex-card {
            flex-direction: row;
          }
          .gallery-card__description-row {
            letter-spacing: -0.3px;
            margin-left: 1.5em;
          }
          @media (min-width: 1068px) {
            .gallery-card__description-row {
              margin-left: 5.25rem;
            }
          }
        }
      }
      &__title {
        opacity: 0.75 !important;
        margin-bottom: 0.91em;
      }
      &__image-wrapper,
      &__description-row {
        opacity: 1 !important;
      }
      &__flex-card {
        display: flex;
        align-items: center;
      }
      &__image-wrapper {
        width: 16.9em;
        height: 22.5em;
        justify-content: center;
      }
    }
    @media (min-width: 1068px) {
      &__flex {
        font-size: 2.04rem;
      }
    }
  }
  @media (min-width: 768px) {
    .gallery-card__affix
      .gallery-card__flex-card.gallery-card__flex-card--landscape {
      flex-direction: column;
      .gallery-card__flex.gallery-card__flex {
        margin-left: 0;
        margin-right: 0;
      }
      .gallery-card__image-wrapper {
        width: 768px;
        height: 576px;
        margin-left: -38px;
        margin-right: -38px;
      }
    }
    .gallery-card__affix
      .gallery-card__description-row.gallery-card__description-row--landscape {
      max-width: 66.67%;
      margin: 1.2em 0 0;
    }
    // remove sticky scrolling effect
    min-height: auto !important;
  }
  @media (min-width: 1068px) {
    .gallery-card__affix
      .gallery-card__flex-card.gallery-card__flex-card--landscape {
      .gallery-card__image-wrapper {
        width: 980px;
        height: 735px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .gallery-card__affix {
      > div + div {
        margin-top: 5.86em;
      }
      .gallery-card__title {
        margin-bottom: 1.25em;
      }
    }
  }
  @media (min-width: 768px) {
    .hidden {
      display: block !important;
    }
  }
}
