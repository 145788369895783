@import '../color.scss';

.about-me-holder {
  color: rgb(180, 180, 187);
  width: 100%;
  min-height: 100vh;
  margin-top: 0;
  padding-top: 2em;
  background-color: $dark-background;

  .about-me {
    &__grid-layout {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: auto;
      margin: 0;
      row-gap: 2.5em;
      > :nth-child(2n - 1) {
        padding-left: 0;
      }
      > :nth-child(2n) {
        padding-right: 0;
      }
      > :last-child {
        margin-bottom: 2em;
      }
      > div {
        margin-top: 0;
        font-size: 1.3em;
        font-weight: 600;
        font-style: normal;
      }
    }
    &__headline-secondary {
      margin-top: 0;
      font-size: 2em;
      color: $text-small;
    }
    &__headline-main {
      font-size: 2.25em;
      line-height: 1em;
      letter-spacing: -1px;
      color: $text-main;
      &--secondary {
        font-size: 1.5em;
        line-height: 1em;
        letter-spacing: -0.6px;
        color: $text-medium;
      }
      &--italicized {
        font-style: italic;
      }
    }
    &__headline-small {
      font-size: 0.8em;
      color: $text-small;
      letter-spacing: -0.31px;
    }
    &__section-title {
      font-size: 2em;
      color: $text-secondary;
      text-transform: capitalize;
      margin-top: 0.5em;
      margin-bottom: 0.75em;
    }
    &__section-text {
      margin-top: 1.5em;
      line-height: 1.1em;
    }
    &__section-container {
      margin-top: 78px;
    }
  }

  div[class^='about-me__headline'] p {
    margin-top: 0;
    margin-bottom: 0;

    + p {
      margin-top: 0.1em;
    }
  }

  div[class^='about-me__headline-main']
    + div[class^='about-me__headline-main'] {
    margin-top: 0;
  }

  .about-me__text-section + .about-me__text-section {
    margin-top: 3em;
  }

  @media (min-width: 768px) {
    .about-me__grid-layout {
      div.about-me__main-section {
        margin-top: -62px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .about-me__headline-main {
        letter-spacing: -0.7px;
      }
      .about-me__headline-small {
        font-size: 0.54em;
        margin-top: 1.5em;
      }
      .about-me__headline-small p + p {
        margin-top: 0;
      }

      .about-me__intro-section {
        min-height: 100vh;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .about-me__intro-section.full-width-display {
        max-width: unset;
      }
      .about-me__main-section,
      .about-me__intro-section {
        max-width: 66.7%;
      }
      .about-me__text-section + .about-me__text-section {
        margin-top: 3em;
      }
      .about-me__section-title {
        margin-top: 0;
      }
      .about-me__project-section {
        .gallery-card__title,
        > .about-me__section-title {
          text-align: center;
        }
        .about-me__section-title {
          padding-top: 0.8em;
        }
      }
    }

    .about-me__next-btn {
      position: fixed;
    }
  }
}
