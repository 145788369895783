.page-down-btn-holder {
  width: 3em;
  height: 3em;
  .page-down-btn {
    width: 3.5em;
    height: 3.5em;
    border: 0;
    background-color: transparent;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.45;
  }
  .page-down-btn:hover {
    opacity: 0.75;
  }
}
.page-down-btn-holder:hover {
  .page-down-btn {
    display: block;
  }
}
