:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  background-color: #ffffff;
  margin: 0;
  font-family: 'Helvetica, Arial, sans-serif';
}

body * + * {
  margin-top: 0.5em;
}

#root,
main {
  margin-top: 0;
}

.fluid-container {
  margin-left: 5%;
  margin-right: 5%;
}
@media (min-width: 768px) {
  .about-me-holder {
    .fluid-container {
      width: 692px;
      margin-right: auto;
      margin-left: auto;
      .about-me__grid-layout {
        > .about-me__main-section,
        > .about-me__intro-section {
          font-size: 1.75em;
        }

        > .about-me__project-section {
          font-size: 1.6em;
        }

        .thread__grid-layout {
          > :nth-child(3n - 1),
          > :nth-child(3n) {
            font-size: 0.6em;
          }
        }
      }
    }
  }
}
@media (min-width: 1068px) {
  .about-me-holder {
    .fluid-container {
      width: 980px;
      margin-right: auto;
      margin-left: auto;
      .about-me__grid-layout {
        > .about-me__main-section,
        > .about-me__intro-section {
          font-size: 2em;
        }
        > .about-me__project-section {
          font-size: 2em;
        }

        .thread__grid-layout {
          > :nth-child(3n - 1),
          > :nth-child(3n) {
            font-size: 0.6em;
          }
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.development-msg-container {
  padding: 2em;
  border-radius: 8px;
  border: 2px dashed orange;
  text-align: center;
  &__date-row {
    text-align: right;
  }
}
