@import '../../../styles/color.scss';

.nav-menu-holder {
  position: relative;
  margin-top: 0;
  .nav-menu {
    &__toggle-btn {
      position: absolute;
      font-size: 2em;
      border: 0;
      cursor: pointer;
      background-color: transparent;
      top: 0.4em;
      right: 0.8em;
      width: 1.2em;
      height: 1.2em;
      text-indent: 10em;
      overflow: hidden;
    }
    &__toggle-btn::after {
      position: absolute;
      color: #ffffff;
      content: '\2261';
      display: block;
      top: 0.05em;
      right: 0.28em;
      text-indent: 0;
    }
    &__dropdown-container {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      margin: 0;
    }
    &__dropdown-container--is-open {
      display: block;
    }
    &__dropdown-list {
      margin: 0;
      padding-left: 0;
      border: 1px solid black;
      list-style: none;
      background-color: $nav-bar-background;
      color: white;
      > li + li {
        border-top: 1px solid grey;
        margin: 0;
      }
      > li:hover {
        background-color: #1a1a1a;
      }
      > li > a {
        display: block;
        padding: 0.52em 1em;
        text-indent: 1.2em;
        text-decoration: none;
        color: white;
      }
    }

    @media (min-width: 768px) {
      &__toggle-btn {
        display: none;
      }
      &__dropdown-container {
        position: static;
        display: block;
      }
      &__dropdown-list {
        display: flex;
        border: 0;
        padding: 0.1em 0.1em;
        > li {
          flex: 0 0 6.5em;
        }
        > li:hover {
          background-color: rgb(179, 179, 179);
          > a {
            color: black;
          }
        }
        > li + li {
          border: 0;
        }
        > li > a {
          padding: 0.3em;
          text-indent: 0;
          text-align: center;
        }
      }
    }
  }
}
