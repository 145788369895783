@import '../../../styles/color.scss';

.error-page-holder {
  margin-top: 0;
  background-color: $dark-background;
  color: $text-main;
  .error-page {
    &__wrapper {
      p,
      h1 {
        margin-top: 0;
        margin-bottom: 0;
      }
      h1 {
        font-size: 5em;
      }
      p {
        font-size: 3em;
      }
      height: calc(100vh - 122px);
    }
    &__content {
      padding-top: 10vh;
    }
  }
}
