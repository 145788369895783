@import '../../../styles/color.scss';

.gallery-section-holder {
  a {
    color: $text-description;
  }
  .gallery-card-holder + .gallery-card-holder {
    margin-top: 6em;
  }
}
